import React from 'react';
import { Link } from 'gatsby';
import Container from 'components/Container';

const Hero = ({ title }) => (
  <div className="bg-primary text-white pt-16 pb-32 md:pt-32 md:pb-64">
    <Container>
      <div className="text-2xl sm:text-4xl md:text-6xl sm:font-bold text-center leading-none md:px-20">
        {title}
      </div>
    </Container>
  </div>
);

export default Hero;
import React from 'react';

import Header from 'components/Header';
import Hero from 'components/Hero';
import Container from 'components/Container';
import Footer from 'components/Footer';

const Layout = ({ title, children }) => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Header />
      <Hero title={title}/>

      <Container className="flex-1">
        <main className="bg-white p-4 sm:p-16 md:p-32 -mt-16 md:-mt-32 shadow-xl relative z-10">
          {children}
        </main>
      </Container>
      <Footer />
    </div>
  )
};

export default Layout;

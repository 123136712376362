import React from 'react';
import { Link } from 'gatsby';
import Container from 'components/Container';

const Header = () => (
  <div className="bg-primary text-white py-6 sticky top-0 z-50">
    <Container>
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">
          <Link to="/" className="">Built by Damian</Link>
        </h1>

        {
          false &&
          <nav className="flex items-center">
            <Link to="/" className="block ml-8 py-2">Home</Link>
            <Link to="/about" className="block ml-8 py-2">About</Link>
          </nav>
        }
      </div>
    </Container>
  </div>
);

export default Header;